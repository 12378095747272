import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

class StolenMemoriesCollection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstTabOn: true
    }

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(e) {
    this.setState(prevState => ({
      firstTabOn: !prevState.firstTabOn 
    }))
  }

  render() {
    return (
      <Layout>
        <SEO title="Stolen Memories Collection" />

        <section className="hero-banner">
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.collection_banner.childImageSharp.fluid} />
              <h1 className="hero-banner__title">
                Stolen Memories
              </h1>
              <h2 className="hero-banner__subtitle">
                Stolen Memories Collection is very close to my heart because the inspiration comes from faraway childhood memories of living in a post-soviet country. Being a child I was so inspired by my parents’ youth photographs (70s and 80s), the way they dressed, and the whole look. Then came the 90s when living in a post soviet country was demoralising, and my interest for clothes was even more discouraging, because of the "look alike” tendency, and everyone who attempted to get out of the box like myself, wouldn’t be understood. My intent with this collection was to mix up all the above mentioned life moments and tell you my new story.
              </h2>
            </div>
          </div>
        </section>

        <section className="collection-wrapper">
          
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.MG_9964.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0010.childImageSharp.fluid} />
            </div>
          </div>
          
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.MG_0025.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0033.childImageSharp.fluid} />
            </div>
          </div>
          
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.MG_0048.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0060.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0062.childImageSharp.fluid} />
            </div>
          </div>
          
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.MG_0079.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0085.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0100.childImageSharp.fluid} />
            </div>
          </div>
          
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.MG_9990.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_9996.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0002.childImageSharp.fluid} />
            </div>
          </div>
          
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.MG_0107.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0131.childImageSharp.fluid} />
            </div>
            <div className="column">
              <Img fluid={this.props.data.MG_0146.childImageSharp.fluid} />
            </div>
          </div>

        </section>
      </Layout>
    )
  }
}

StolenMemoriesCollection.propTypes = {
  
}

export default StolenMemoriesCollection

export const fluidImageStolenMemoriesBanner = graphql`
fragment fluidImageStolenMemoriesBanner on File {
  childImageSharp {
    fluid(maxWidth: 2560) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const fluidImageStolenMemories = graphql`
fragment fluidImageStolenMemories on File {
  childImageSharp {
    fluid(maxWidth: 1280) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    collection_banner: file(relativePath: { eq: "collections/stolen-memories/collection_banner.jpg" }) {
      ...fluidImageStolenMemoriesBanner
    }
    MG_0002: file(relativePath: { eq: "collections/stolen-memories/MG_0002.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0010: file(relativePath: { eq: "collections/stolen-memories/MG_0010.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0025: file(relativePath: { eq: "collections/stolen-memories/MG_0025.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0033: file(relativePath: { eq: "collections/stolen-memories/MG_0033.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0048: file(relativePath: { eq: "collections/stolen-memories/MG_0048.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0060: file(relativePath: { eq: "collections/stolen-memories/MG_0060.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0062: file(relativePath: { eq: "collections/stolen-memories/MG_0062.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0079: file(relativePath: { eq: "collections/stolen-memories/MG_0079.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0085: file(relativePath: { eq: "collections/stolen-memories/MG_0085.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0100: file(relativePath: { eq: "collections/stolen-memories/MG_0100.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0107: file(relativePath: { eq: "collections/stolen-memories/MG_0107.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0131: file(relativePath: { eq: "collections/stolen-memories/MG_0131.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_0146: file(relativePath: { eq: "collections/stolen-memories/MG_0146.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_9964: file(relativePath: { eq: "collections/stolen-memories/MG_9964.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_9983: file(relativePath: { eq: "collections/stolen-memories/MG_9983.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_9990: file(relativePath: { eq: "collections/stolen-memories/MG_9990.jpg" }) {
      ...fluidImageStolenMemories
    }
    MG_9996: file(relativePath: { eq: "collections/stolen-memories/MG_9996.jpg" }) {
      ...fluidImageStolenMemories
    }
  }
`
